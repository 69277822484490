// http://ionicframework.com/docs/theming/
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.handle-safe-area {
  --padding-bottom: var(--ion-safe-area-bottom); // for iOS, bottom bar overlayed on UI
}

main {
  background: red!important;
  }

::-webkit-scrollbar {
  width: 15px;
  background: var(--ion-color-primary);
  border-radius: 26px;
}

::-webkit-scrollbar-thumb {
  background: #687984;
  border-radius: 26px;
  height: 44px;
  border: 2px solid var(--ion-color-primary);
}

body {
  background: url('./assets/images/G3_7.png') no-repeat !important;
}

@media (min-width: 768px) {
  body {
    overflow-y: auto !important;
    position: unset;
    background: url('./assets/images/G3_7.png') no-repeat!important;

        background-size: cover !important;

    &::-webkit-scrollbar {
      width: 15px;
      background: var(--ion-color-primary);
      border-radius: 26px;
    }

    /* Track */
    &::-webkit-scrollbar-thumb {
      background: #687984;
      border-radius: 26px;
      height: 44px;
      border: 2px solid var(--ion-color-primary);
    }
  }
}

/* updated globally since changes to IONIC 6 */
// toolbar


ion-content, ion-toolbar, ion-item {
  --background: inherit!important;
}

// use this for showing pointer
.cursor-pointer {
  cursor: pointer;
}

.qr-code-custom {
  img {
    border-radius: 15px;
  }
}

.alert-scroll {
  overflow-y: auto;
  max-height: 300px;
}

.alert-radio-group::-webkit-scrollbar {
  width: 3px;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.alert-message {
  &.sc-ion-alert-md {
    color: lightgray !important;
  }
}

ion-modal.logout-modal-desktop {
  --border-color:   #707070;
--border-radius:  24px;
--border-style:   solid;
--border-width: 1px;
}
ion-modal {
  --backdrop-opacity: 0.7;
  --border-radius: 5px;

  @media only screen and (min-width: 769px) {
    --height: 95%;
    --width: 450px;
  }
}

/* mainly focused for modal backdrop (change css varible for customization) */
ion-backdrop {
  background: #000;
  --backdrop-opacity: 0.7;
}

.modal-wrapper {
  background: transparent !important;
}

.two-fa-modal {
  &::part(content) {
    border-radius: 15px !important;
    max-height: 562px !important;
  }

  @media (max-width: 500px) {
    &::part(content) {
      max-height: 100% !important;
    }
  }
}

.tour-guide-modal {
  @media (max-width: 768px) {
    &::part(content) {
      --background: #1b2831 !important;
    }
  }

  @media (min-width: 769px) {
    &::part(content) {
      --background: transparent !important;
    }
  }

  &::part(content) {
    --box-shadow: none !important;
    width: 100% !important;
    height: 100% !important;
  }

  .modal-wrapper {
    top: 0 !important;
    position: absolute !important;
    height: 100% !important;
    width: 100% !important;
    left: 0px;
    --background: transparent !important;
    --box-shadow: none !important;
    // ion-page{
    //   overflow: visible !important;
    // }
  }
}

ion-back-button {
  --icon-font-size: 30px;
}

ion-loading {
  z-index: 15 !important;
}

ion-button {
  outline: none;
  font-weight: bold;
}

ion-avatar {
  outline: none;
}

ion-back-button button {
  padding-left: 0 !important;
}

.larger-hamburger-menu {
  font-size: 20px !important;
}

/* removes the faint 'active' highlight that happens only on native after long-press */
ion-item {
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
}

/* removes number up/down arrows from numeric inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.disable-modal {
  --height: 80%;
  --border-radius: 10px;
  padding: 25px;
}

/* ion modal auto-height from https://github.com/ionic-team/ionic-framework/issues/16852#issuecomment-449433452 */
ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: 800px;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

.token-modal-css .modal-wrapper {
  height: 80%;
  top: 10%;
  max-width: 488px;
  position: absolute;
  display: block;
  border-radius: 15px;
}

.success-modal {
  --border-radius: 20px;

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    --height: 95%;
    --width: 450px;
  }

  &-desktop {
    --height: 50%;
    --border-radius: 20px;
  }
}

.primary-address-success-modal {
  --border-radius: 20px;

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    --height: 95%;
    --width: 450px;
  }

  &-desktop {
    --height: 50%;
    --border-radius: 20px;
  }
}

.deposit-modal {
  --border-radius: 20px;

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    --height: 95%;
    --width: 450px;
  }

  &-desktop {
    --height: 60%;
    --border-radius: 20px;
  }
}

.confirm-modal {
  --backdrop-opacity: 0.7;
  --border-radius: 0px;

  &::part(backdrop) {
    --backdrop-opacity: 0.7;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    --height: 95%;
    --width: 450px;
  }

  &-desktop {
    &::part(backdrop) {
      --backdrop-opacity: 0.7;
    }

    --height: 65%;
    --border-radius: 20px;
  }
}


.add-new-token-modal {
  --border-radius: 20px;

  &::part(backdrop) {
    --backdrop-opacity: 0.7;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    --height: 95%;
    --width: 450px;
  }

  &-desktop {
    &::part(backdrop) {
      --backdrop-opacity: 0.7;
    }

    --height: 65%;
    --border-radius: 20px;
  }
}


.purchase-gas-modal {
  --border-radius: 20px;

  &::part(backdrop) {
    --backdrop-opacity: 0.7;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    --height: 95%;
    --width: 450px;
  }

  &-desktop {
    &::part(backdrop) {
      --backdrop-opacity: 0.7;
    }

    --height: 50%;
    --border-radius: 20px;
  }
}

.select-asset-modal {
  --border-radius: 20px;

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    --height: 95%;
    --width: 450px;
  }

  &-desktop {
    --height: 80%;
  }
}

.expiry-order-modal {
  --height: 65%;
  --border-radius: 20px 20px 0px 0px;

  &::part(content) {
    position: absolute;
    bottom: -15px;
    width: 97%;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    --height: 65%;
    --width: 450px;
  }

  &-desktop {
    --height: 45%;
    --border-radius: 20px;
  }
}

.kyc-modal {
  @media only screen and (min-width: 501px) and (max-width: 768px) {
    --height: 95%;
    --width: 450px;
  }

  &-desktop {
    --height: 95%;
    --border-radius: 20px;
  }
}

.simplex-widget-modal {
  @media only screen and (min-width: 501px) and (max-width: 768px) {
    --height: 95%;
    --width: 450px;
  }

  &-desktop {
    --height: 80%;
    --border-radius: 20px;
  }
}

.payment-tran-modal {
  &::part(content) {
    height: 25rem;
    width: 350px;
    border-radius: 20px;
  }
}

ion-card.graph-card {
     border-color: #707070;
    border-width: 1px;
    border-style: solid;
    border-radius: 36px;
    filter: drop-shadow(30px 30px 25px rgba(0, 0, 0, 0.6));
    background: none!important;
}

 .fiat-item {
    background: var(--ion-color-primary)!important;
    border-radius: 20px!important;
  }

.send-asset-modal {
  --height: 100%;
  --width: 100%;

  &-desktop {
    --height: 90%;
  }
}

.usd-balance-home-modal {
  --height: 100%;
  --width: 100%;

  &-desktop {
    --height: 85%;
    --border-radius: 20px;
  }
}

.receive-asset-modal-des-css .modal-wrapper {
  height: 70%;
  top: 15%;
  max-width: 420px;
  position: absolute;
  display: block;
  border-radius: 15px;
}

.receive-asset-modal-mob-css .modal-wrapper {
  height: 100%;
  max-width: 488px;
  position: absolute;
  display: block;
}

.delete-modal-css .modal-wrapper {
  height: 80%;
  top: 10%;
  max-width: 488px;
  position: absolute;
  display: block;
  border-radius: 15px;
}

.ach-modal-css .modal-wrapper {
  height: 80%;
  top: 10%;
  width: 700px;
  position: absolute;
  display: block;
  border-radius: 15px;
}

.before-activation-desktop {
  transform: translateX(var(--sidebar-width));
  --width: 100vw;
  --height: 100vh;
  --background: transparent;

  .modal-wrapper {
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: 0px;
    margin-right: auto;
    display: block;
  }

  ion-backdrop {
    width: 100vw;
  }
}

ion-popover.unavailable-service-popover-custom-class {
  --max-width: 180px;

  ion-backdrop {
    opacity: 0 !important;
  }
}

// Big modals
ion-modal.big-modal.desktop {
  --height: 85%;
  --border-radius: 30px;
}

// consider adding this to match UI with side menu opened
.with-side-menu {
  margin-left: var(--sidebar-width); // added side menu
}

.logout-modal {

  // adjust for tabs
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    --width: 450px;
    --height: 500px;
  }

  &::part(content) {
    border-radius: 24px !important;
    border: 1px solid #707070;
  }

  &-desktop {
    &::part(content) {
      height: 500px !important;
      border-radius: 20px !important;
    }
  }
}

.logout-reset-wallet-modal {

  // adjust for tabs
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    --width: 450px;
  }

  &::part(content) {
    border-radius: 20px !important;
  }

  &-desktop {
    &::part(content) {
      height: 36rem !important;
      border-radius: 20px !important;
    }
  }
}

.delete-modal {

  // adjust for tabs
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    --width: 450px;
  }

  &::part(content) {
    border-radius: 20px !important;
  }

  &-desktop {
    &::part(content) {
      height: 24rem !important;
      border-radius: 20px !important;
    }

    .ion-page {
      border-radius: 20px !important;
    }
  }
}

.delete-modal-confirm {
  &-desktop {
    &::part(content) {
      border-radius: 20px !important;
    }

    .ion-page {
      border-radius: 20px !important;
    }
  }
}

.update-password-modal {
  &-desktop {
    &::part(content) {
      height: 75%;
      border-radius: 20px;
    }

    .ion-page {
      border-radius: 20px !important;
    }
  }
}

.signature-request-modal {
  --height: 65%;
  --border-radius: 10px;
}

.confirm-address-modal {
  --height: 60%;
  --border-radius: 10px;
}

.confirm-address-modal {
  --height: 60%;
  --border-radius: 10px;
}

.success-or-failure-modal {
  --height: 50%;
  --border-radius: 10px;
}

.investors-modal-desktop {
  --height: 75% !important;
  --width: 60% !important;
  --border-radius: 20px !important;
}

.agx-tos-modal {
  &::part(content) {
    border-radius: 20px;
  }

  &-desktop {
    &::part(content) {
      height: 80%;
      border-radius: 20px;
    }

    .ion-page {
      border-radius: 20px !important;
    }
  }

  &-agreement {
    &::part(content) {
      height: 60%;
      border-radius: 20px;
    }
  }
}

.vault-modal {
  &::part(content) {
    height: 25rem;
    width: 300px;
    border-radius: 20px;
  }
}

.fullscreen-info-modal {
  &::part(content) {
    width: 100vw;
    height: 100vh;
    background: transparent;
  }
}

.fullscreen-modal {
  &::part(content) {
    width: 100vw;
    height: 100vh;
  }
}

.vault-promo-modal {
  &::part(content) {
    height: 35rem;
    width: 350px;
    border-radius: 20px;
  }
}

.trade-order-type-modal {
  &::part(content) {
    height: 20rem;
    width: 350px;
    border-radius: 20px;
  }
}

.kyc-confirm-modal {
  &::part(backdrop) {
    --backdrop-opacity: 0.7;
  }

  &::part(content) {
    height: 20rem;
    width: 350px;
    border-radius: 20px;
  }
}

.twofa-model-des-css {
  &::part(content) {
    height: 60%;
    max-width: 450px;
    position: absolute;
    display: block;
    border-radius: 15px;

    @media (min-height: 800px) {
      height: 50%;
    }

    @media (min-height: 900px) {
      height: 40%;
    }
  }
}

.twofa-model-des-css .modal-wrapper {
  height: 60%;
  max-width: 450px;
  position: absolute;
  display: block;
  border-radius: 15px;
}

.twofa-model-mob-css .modal-wrapper {
  position: absolute;
  display: block;
  border-radius: 15px;
}

.twofa-deviceList {
  &::part(content) {
    --width: 430px;
    height: 68%;
    border-radius: 15px;

    @media (min-height: 800px) {
      height: 50%;
    }

    @media (min-height: 900px) {
      height: 40%;
    }

    @media (max-width: 765px) {
      --width: 100%;
      height: 100%;
    }
  }
}

.twofa-deviceList {
  .modal-wrapper {
    --width: 430px;
    height: 68%;
    border-radius: 15px;

    @media (max-width: 768px) {
      --width: 100%;
      height: 100%;
    }
  }
}

.mat-select-placeholder {
  color: #7c858b !important;
}

div.app-dropdown mat-select {
  border: 1px solid var(--ion-color-tertiary) !important;
}

.btn-spinner {
  position: absolute;
  right: 0px;
  width: 15px;
  color: white;
}

.iti .iti__country-list {
  width: 0;
  min-width: 19rem;
}

.iti .dropdown-menu.country-dropdown {
  min-width: 19rem;
}

.apexcharts-yaxis-label {
  fill: #fdfdfd;
}

.show-more {
  margin-top: 10px;

  ion-col {
    text-align: center;

    ion-button {
      text-transform: capitalize;
      font-size: 0.8rem;
    }
  }
}
.button-default {
  min-height: 50px;
}
.prevent-select {
  * {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  input {
    -webkit-user-select: auto !important;
    -khtml-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }
}
send-asset-modal.ion-page {
   border-radius: 14px;
  border: 1px solid var(--ion-color-tertiary);
}

ion-modal.logout-modal .ion-page {
  border: 1px solid #707070;
  border-radius: 24px;
}
